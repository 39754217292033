<template>
  <app-loading-container
    class="w-full px-4 py-12 mb-32 lg:py-12 sm:px-10"
    :loading="loading"
    :loaded="loaded"
    :error-loading="errorLoading"
  >
    <div>
      <csv-download-link
        v-if="loaded"
        :data="routes"
        :fields="[
          { label: 'Pickup', value: 'pickup' },
          { label: 'Destination', value: 'destination' },
          { label: 'Staff Trips', value: 'total_trips' },
        ]"
        download-name="staff-shuttlers-trip-metrics.csv"
        class="absolute bottom-0 right-0 mb-6 mr-6"
      >
        <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle fill="#20E682" cx="28" cy="28" r="28" />
            <path d="M14 14h28v28H14z" />
            <path
              stroke="#FFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M23.406 26.031L28 30.625l4.594-4.594M28 18.372v12.25"
            />
            <path
              d="M38.5 28.875v7.875a.875.875 0 01-.875.875h-19.25a.875.875 0 01-.875-.875v-7.875"
              stroke="#FFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </csv-download-link>
      <div
        class="items-center mt-6 space-y-4 lg:space-y-0 lg:space-x-4 lg:flex"
      >
        <div class="flex-1">
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4 pointer-events-none text-gray-type-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <input
              v-model="search"
              type="search"
              class="w-full h-12 px-3 py-4 pl-10 text-xs font-medium bg-white outline-none plachol placeholder-gray-type-3 focus:outline-none"
              placeholder="Search routes"
            />
          </div>
        </div>
        <div class="space-y-4 lg:flex lg:space-x-4 lg:space-y-0">
          <div class="p-4 bg-white border rounded border-gray-type-7">
            <div class="flex items-center justify-between">
              <div class="inline-flex">
                <span class="text-xs font-medium text-gray-type-2"
                  >Start date:</span
                >
                <datepicker
                  v-model="params.start_date"
                  ref="startDatePicker"
                  class="w-20 ml-2 text-xs font-medium border-0 focus:outline-none text-gray-type-8"
                  :config="{
                    wrap: true,
                    altFormat: 'j M Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                  }"
                ></datepicker>
              </div>
              <button
                class="ml-8 focus:outline-none"
                @click="$refs.startDatePicker.fp.open()"
              >
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h20v20H0z" />
                    <rect
                      stroke="#737D78"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      x="3.125"
                      y="3.125"
                      width="13.75"
                      height="13.75"
                      rx="3"
                    />
                    <path
                      stroke="#737D78"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.773 1.875v2.5M6.273 1.875v2.5M3.125 6.898h13.75"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div class="p-4 bg-white border rounded border-gray-type-7">
            <div class="flex items-center justify-between">
              <div class="inline-flex">
                <span class="text-xs font-medium text-gray-type-2"
                  >End date:</span
                >
                <datepicker
                  v-model="params.end_date"
                  ref="endDatePicker"
                  class="w-20 ml-2 text-xs font-medium border-0 focus:outline-none text-gray-type-8"
                  :config="{
                    wrap: true,
                    altFormat: 'j M Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    minDate: params.start_date,
                  }"
                ></datepicker>
              </div>
              <button
                class="ml-8 focus:outline-none"
                @click="$refs.endDatePicker.fp.open()"
              >
               <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h20v20H0z" />
                    <rect
                      stroke="#737D78"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      x="3.125"
                      y="3.125"
                      width="13.75"
                      height="13.75"
                      rx="3"
                    />
                    <path
                      stroke="#737D78"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.773 1.875v2.5M6.273 1.875v2.5M3.125 6.898h13.75"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <app-table
          :items="filteredRoutes"
          :fields="reportFields"
          :loading="loading"
          :error-loading="errorLoading"
        >
          <template v-slot:route_name="{ item }">
            <div class="text-xs font-medium text-gray-type-3">
              {{ item.pickup }} - {{ item.destination }}
            </div>
          </template>
        </app-table>
      </div>
    </div>
  </app-loading-container>
</template>

<script>
import { mapGetters } from "vuex";
import AppTable from "@/components/AppTable";
import CsvDownloadLink from "@/components/CsvDownloadLink";

export default {
  components: {
    AppTable,
    CsvDownloadLink,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      errorLoading: false,
      routes: [],
      reportFields: [
        { key: "route_name", label: "Route" },
        { key: "total_trips", label: "No. of trips" },
      ],
      search: "",
      params: {
        end_date: new Date(),
        start_date: `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-1`,
      },
    };
  },
  created() {
    this.fetchRoutes();
  },
  watch: {
    "params.start_date"(date) {
      if (date <= this.params.end_date) {
        this.fetchRoutes();
      }
    },
    "params.end_date"(date) {
      if (date >= this.params.start_date) {
        this.fetchRoutes();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    filteredRoutes() {
      return this.routes.filter(
        (route) =>
          route.pickup.toLowerCase().includes(this.search.toLowerCase()) ||
          route.destination.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    fetchRoutes() {
      this.loading = true;

      this.errorLoading = this.loaded = false;

      this.$http
        .post(`/corporates/${this.user.id}/route_metrics`, {
          ...this.params,
        })
        .then((res) => {
          this.routes = res.data;
          this.loaded = true;
        })
        .catch(() => {
          this.errorLoading = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>